<template lang='pug'>
  .tours-and-active
    .header
      .content.constrainer
        img.header-image(src='@/assets/jpg/toursandactivepage.jpg')
        h1.title Book tours and activities.
        .dashes
          .dash(v-for='n in 5')
        .para.fs There are plenty of ways to experience Iceland, browse through some of our favorites.
    .body-section.gutter.constrainer(ref="secondBlock")
      .book-tours
        .left
          h1.title Book tours with professionals.
          .dashes
            .dash(v-for='n in 5')
          .para.fs You can’t take your smart car to the glaciers, no matter how well you prepare. In that case we think, the best way to experience Iceland is with the deft hand and reliable transports of tour guides. Here we’ve collected some of our favorite tours.
        .right
          .iceland
            img.austur(src='@/assets/map/yellow/austur.svg' usemap='#image-map' :class='{ active: selectedMapParts["austur"] }')
            img.nordur(src='@/assets/map/yellow/nordur.svg' usemap='#image-map' :class='{ active: selectedMapParts["nordur"] }')
            img.rvk(src='@/assets/map/yellow/rvk.svg' usemap='#image-map' :class='{ active: selectedMapParts["rvk"] }')
            img.sudur(src='@/assets/map/yellow/sudur.svg' usemap='#image-map' :class='{ active: selectedMapParts["sudur"] }')
            img.vestfirdir(src='@/assets/map/yellow/vestfirdir.svg' usemap='#image-map' :class='{ active: selectedMapParts["vestfirdir"] }')
            img.vestur(src='@/assets/map/yellow/vestur.svg' usemap='#image-map' :class='{ active: selectedMapParts["vestur"] }')
            img.reykjanes(src='@/assets/map/yellow/reykjanes.svg' usemap='#image-map' :class='{ active: selectedMapParts["reykjanes"] }')
            img.austur(src='@/assets/map/blue/austur.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["austur"] }')
            img.nordur(src='@/assets/map/blue/nordur.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["nordur"] }')
            img.rvk(src='@/assets/map/blue/rvk.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["rvk"] }')
            img.sudur(src='@/assets/map/blue/sudur.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["sudur"] }')
            img.vestfirdir(src='@/assets/map/blue/vestfirdir.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["vestfirdir"] }')
            img.vestur(src='@/assets/map/blue/vestur.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["vestur"] }')
            img.reykjanes(src='@/assets/map/blue/reykjanes.svg' usemap='#image-map' :class='{ inactive: selectedMapParts["reykjanes"] }')
          map(name="image-map")
            area(@click='selectMap(1)' coords="120,139,162,115,169,69,110,9,74,12,44,52,11,114,0,130,29,141,70,131,95,131" shape="poly")
            area(@click='selectMap(2)' coords="125,147,98,164,102,173,126,176,140,167,133,184,114,182,90,181,22,203,34,224,102,219,118,256,129,263,125,272,139,276,156,284,229,227" shape="poly")
            area(@click='selectMap(3)' coords="146,286,150,308,128,317,120,293,131,287" shape="poly")
            area(@click='selectMap(4)' coords="238,232,158,295,158,310,141,323,151,337,179,338,221,365,248,374,299,383,345,370,360,343,401,341,452,296,478,282,503,282,518,266,398,215" shape="poly")
            area(@click='selectMap(5)' coords="406,206,522,255,572,190,570,172,568,128,530,106,530,93,506,94,516,78,513,55,499,63" shape="poly")
            area(@click='selectMap(6)' coords="237,225,394,205,492,61,497,37,514,23,502,15,466,41,455,33,458,17,444,7,438,1,410,4,414,28,415,48,395,49,383,39,372,49,365,73,355,65,342,46,330,47,320,46,325,76,334,91,332,106,315,72,303,49,294,40,281,49,263,59,258,74,262,101,250,86,231,53,210,58,217,103,205,121,201,106,181,121,179,136,174,156,169,122,137,140,131,148" shape="poly")
            area(@click='selectMap(7)' coords="105,313,96,301,91,311,93,334,115,333,132,334,135,319,117,309" shape="poly")
          .pick-a-location.desktop
            .categories Categories
            .top
              .category-picker(v-click-outside='closeDropA' :class='{ active: showDropA }')
                .heading.clickable(@click='showDropA = !showDropA')
                  .text {{ selectedInList }}
                  img.caret(src='@/assets/icons/caret_black.svg')
                .content(:class='{ active: showDropA }')
                  .item.clickable(v-for='item in categorySelection' :key="item.id" @click='item.selected = !item.selected; selectMap(item.id)' :class='{ selected: selected === item.name }')
                    .checkmark
                      img(src='@/assets/check2.svg')
                    .text {{ item.engname }}
          .pick-a-location.mobile
            .top
              .categories Categories
              .category-picker(v-click-outside='closeDropB' :class='{ active: showDropB }')
                .heading.clickable(@click='showDropB = !showDropB')
                  .text {{ selectedInList }}
                  img.caret(src='@/assets/icons/caret_black.svg')
                .content(:class='{ active: showDropB }')
                  .item.clickable(v-for='item in categorySelection' :key="item.id" @click='item.selected = !item.selected; selectMap(item.id)' :class='{ selected: selected === item.name }')
                    .checkmark
                      img(src='@/assets/check2.svg')
                    .text {{ item.engname }}
      .frame
        .frame-content(v-if="!frameContent")
          script(
            type='application/javascript'
            src='https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=0cb29d2f-258c-47d4-86ff-af67afaaf9b8'
            async
          )
          div(
            class='bokunWidget'
            :data-src='`https://widgets.bokun.io/online-sales/0cb29d2f-258c-47d4-86ff-af67afaaf9b8/product-list/${selectedFrameId}`'
          )
          noscript
            | Please enable javascript in your browser to book
</template>

<script>
import ClickOutside from 'vue-click-outside';
export default {
	name: 'ToursAndActive',
	metaInfo: {
		title: 'Safe.is - Tours and activities.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:url',
				property: 'og:url',
				content: 'https://safe.is/tours-active/index.html'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/toursandactive.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'Safe.is - Tours and activities.'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'Browse through selected tours and expeditions giving you the best of Iceland.'
			},
			{
				name: 'description',
				property: 'description',
				content: 'Browse through selected tours and expeditions giving you the best of Iceland.'
			}
		]
	},
	directives: {
		ClickOutside
	},
	props: {
		scroll: {
			default: false,
			type: Boolean
		},
		iframeId: {
			default: 9,
			type: Number
		}
	},
	data: function () {
		return {
			frameContent: document.querySelector('.frame-content'),
			selectedMapParts: {
				austur: false,
				nordur: false,
				rvk: false,
				sudur: false,
				vestfirdir: false,
				vestur: false,
				reykjanes: false
			},
			showDropA: false,
			showDropB: false,
			categorySelection: [
				{ name: 'austur', engname: 'East Iceland', id: 5, selected: false },
				{ name: 'nordur', engname: 'North Iceland', id: 6, selected: false },
				{ name: 'reykjanes', engname: 'Reykjanes', id: 7, selected: false },
				{ name: 'rvk', engname: 'Capital Area', id: 3, selected: false },
				{ name: 'sudur', engname: 'South Iceland', id: 4, selected: false },
				{ name: 'vestfirdir', engname: 'Westfjords', id: 1, selected: false },
				{ name: 'vestur', engname: 'West Iceland', id: 2, selected: false },
				{ name: 'all', engname: 'All tours', id: 9, selected: true },
				{ name: 'private', engname: 'Private tours', id: 8, selected: false },
				{ name: 'diving', engname: 'Diving', id: 10, selected: false },
				{ name: 'fly', engname: 'Fly over Iceland', id: 11, selected: false },
				{ name: 'glaciers', engname: 'Glaciers and Caves', id: 12, selected: false },
				{ name: 'horse', engname: 'Horse Riding Tours', id: 13, selected: false },
				{ name: 'whales', engname: 'Whales and Puffins', id: 14, selected: false }
			],
			selected: 'all',
			selectedFrameId: 22105,
			selectedInList: 'All tours'
		};
	},

	mounted () {
		if (this.scroll) {
			this.$nextTick(() => {
				const block = this.$refs.secondBlock;
				block.scrollIntoView(true);
			});
		}

		if (this.frameContent) {
			this.$el.querySelector('.frame').appendChild(this.frameContent);
			this.$nextTick(() => this.selectMap(this.iframeId));
		} else if (this.iframeId !== 9) {
			this.$nextTick(() => this.selectMap(this.iframeId));
		}

		this.$store.state.keydex += 1;
	},
	beforeDestroy () {
		const frameContent = document.querySelector('.frame-content');
		const frameContentWrapper = document.querySelector('.frame-content-wrapper');

		frameContentWrapper.appendChild(frameContent);
	},
	methods: {
		selectMap (id) {
			if (id === 1) {
				this.selectedMapParts['vestfirdir'] = true;
				this.selected = 'vestfirdir';
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedMapParts['reykjanes'] = false;
				this.selectedFrameId = 22095;
				this.selectedInList = 'Westfjords';
			} else if (id === 2) {
				this.selectedMapParts['vestur'] = true;
				this.selected = 'vestur';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedMapParts['reykjanes'] = false;
				this.selectedFrameId = 22096;
				this.selectedInList = 'West Iceland';
			} else if (id === 3) {
				this.selectedMapParts['rvk'] = true;
				this.selected = 'rvk';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedMapParts['reykjanes'] = false;
				this.selectedFrameId = 22098;
				this.selectedInList = 'Capital Area';
			} else if (id === 4) {
				this.selectedMapParts['sudur'] = true;
				this.selected = 'sudur';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedMapParts['reykjanes'] = false;
				this.selectedFrameId = 22087;
				this.selectedInList = 'South Iceland';
			} else if (id === 5) {
				this.selectedMapParts['austur'] = true;
				this.selected = 'austur';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedMapParts['reykjanes'] = false;
				this.selectedFrameId = 22092;
				this.selectedInList = 'East Iceland';
			} else if (id === 6) {
				this.selectedMapParts['nordur'] = true;
				this.selected = 'nordur';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['reykjanes'] = false;
				this.selectedFrameId = 22094;
				this.selectedInList = 'North Iceland';
			} else if (id === 7) {
				this.selectedMapParts['reykjanes'] = true;
				this.selected = 'reykjanes';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedFrameId = 22100;
				this.selectedInList = 'Reykjanes';
			} else if (id === 8) {
				this.selectedMapParts['reykjanes'] = false;
				this.selected = 'private';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedFrameId = 22101;
				this.selectedInList = 'Private tours';
			} else if (id === 9) {
				this.selectedMapParts['reykjanes'] = false;
				this.selected = 'all';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedFrameId = 22105;
				this.selectedInList = 'All tours';
			} else if (id === 10) {
				this.selectedMapParts['reykjanes'] = false;
				this.selected = 'diving';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedFrameId = 23481;
				this.selectedInList = 'Diving';
			} else if (id === 11) {
				this.selectedMapParts['reykjanes'] = false;
				this.selected = 'fly';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedFrameId = 23486;
				this.selectedInList = 'Fly over Iceland';
			} else if (id === 12) {
				this.selectedMapParts['reykjanes'] = false;
				this.selected = 'glaciers';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedFrameId = 23483;
				this.selectedInList = 'Glaciers and Caves';
			} else if (id === 13) {
				this.selectedMapParts['reykjanes'] = false;
				this.selected = 'horse';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedFrameId = 23480;
				this.selectedInList = 'Horse Riding Tours';
			} else if (id === 14) {
				this.selectedMapParts['reykjanes'] = false;
				this.selected = 'whales';
				this.selectedMapParts['vestfirdir'] = false;
				this.selectedMapParts['vestur'] = false;
				this.selectedMapParts['rvk'] = false;
				this.selectedMapParts['sudur'] = false;
				this.selectedMapParts['austur'] = false;
				this.selectedMapParts['nordur'] = false;
				this.selectedFrameId = 23482;
				this.selectedInList = 'Whales and Puffins';
			}
			if (this.frameContent) {
				this.$el.querySelector('iframe').src = `https://widgets.bokun.io/online-sales/0cb29d2f-258c-47d4-86ff-af67afaaf9b8/product-list/${this.selectedFrameId}?bokunSessionId=6e7b9645-0a96-40e9-851e-271c3ad3d436&hostUrl=http%3A%2F%2Flocalhost%3A8080%2Ftours-active`;
			}
		},
		closeDropA: function () {
			this.showDropA = false;
		},
		closeDropB: function () {
			this.showDropB = false;
		}
	}
};
</script>

<style scoped lang="less" scoped>
.tours-and-active {
  padding-bottom: 49px;
  .body-section {
    .book-tours {
     padding-top: 120px;

      @media screen and (min-width: 1220px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 1220px) {
       padding-top: 92px;
      }
      .right {
        position: relative;
        @media screen and (max-width: 1220px) {
          grid-area: bottom;
        }
        .pick-a-location {
          &.desktop {
            position: relative;
            // translate: 0px -80%;
            transform: translate(0, -80%);
            @media screen and (max-width: 1220px) {
              margin-top: 74px;
              display: none;
            }

            .top {
              height: 48px;
            }

            .categories {
              position: relative;
              left: 40%;
              transform: translate(-50%, 0);
              margin-bottom: 10px;
							width: 100px;
							font-weight: bold;
            }
          }
          &.mobile {
            display: flex;
            justify-content: center;
            margin-top: 75px;
            margin-bottom: 96px;
            @media screen and (max-width: 768px) {
              position: relative;
              margin-top: 0;
            }
            @media screen and (max-width: 600px) {
              margin-top: 35px;
            }
          }
          .top {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: max-width;
            @media screen and (max-width: 1220px) {
              grid-template-columns: 1fr;
            }
            z-index: 30;

            .categories {
							text-align: left;
							font-weight: bold;
            }

            .category-picker {
              position: absolute;
              right: 26%;
              background-color: white;
              outline: solid 1px #f9db49;
              padding-bottom: 0px;
              padding-left: 32px;
              width: 212px;
              height: 48px;
              transition: .3s;
              transition-delay: .3s;
              @media screen and (max-width: 1220px) {
                width: auto;
                margin-top: 10px;
                position: relative;
                right: 0;
                left: 50%;
                transform: translate3d(-50%, 0px, 0px);
              }
              @media screen and (max-width: 400px) {
                width: calc(~'100vw - 82px');
              }
              .heading {
                display: grid;
                grid-template-columns: 1fr 1fr;
                .caret {
                  position: absolute;
                  top: 16px;
                  left: 100%;
                  transition: .3s;
                  transform: translate3d(-100%, 0px, 0px) scaleY(1);
                  padding-right: 24px;
                }
                .text {
                  text-align: left;
                  width: 232.3px;
                  height: 48px;
                  line-height: 48px;
                  font-size: 15px;
                  font-weight: 600;
                  font-style: normal;
                  font-stretch: normal;
                  letter-spacing: 0.6px;
                  color: #4d4d4d;
                  margin-top: auto;
                  margin-bottom: auto;
                  @media screen and (max-width: 1220px) {
                    width: 265px;
                  }
                  @media screen and (max-width: 846px) {
                    width: 200px;
                  }
                  @media screen and (max-width: 477px) {
                    width: 230px;
                  }
                }
              }
              &.active {
                .heading {
                  .caret {
                    transform: translate3d(-100%, 0px, 0px) scaleY(-1);
                  }
                }
                transition-delay: 0s;
                width: 552px;
                height: 751px;
                @media screen and (max-width: 640px) {
                  width: 304px;
                  height: max-content;
                  padding-bottom: 40px;
                  max-width: calc(~'100vw - 82px');
                }
              }
              .content {
                position: relative;
                z-index: 30;
                display: grid;
                grid-template-columns: 1fr 1fr;
                @media screen and (max-width: 640px) {
                  grid-template-columns: 1fr;
                  height: 300px;
                  overflow-y: scroll;
                  margin-right: 31px;
                  &::-webkit-scrollbar {
                    width: 4px;
                  }
                  &::-webkit-scrollbar-track {
                    background: #fafafa;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: #f9db49;
                    border-radius: 4.5px;
                  }
                }
                opacity: 0;
                pointer-events: none;
                transition: .3s;
                transition-delay: 0s;
                &.active {
                  transition-delay: .3s;
                  opacity: 1;
                  pointer-events: initial;
                }
                .item {
                  display: grid;
                  grid-template-columns: 16px auto;
                  grid-column-gap: 16px;
                  margin-bottom: 15px;
                  margin-left: 1px;
                  margin-top: 1px;
                  @media screen and (max-width: 640px) {
                    margin-bottom: 0;
                  }
                  .checkmark {
                    position: relative;
                    top: 50%;
                    img {
                      transition: .3s;
                      opacity: 0;
                    }
                    width: 16px;
                    height: 16px;
                    line-height: 16px;
                    outline: solid 1px #f9db49;
                    transition: .3s;
                    transform: translate3d(0px, -50%, 0px) rotate3d(1, 1, 0, 180deg);
                  }
                  &.selected {
                    .checkmark {
                      transform: translate3d(0px, -50%, 0px) rotate3d(1, 1, 0, 0deg);
                      background-color: #f9db49;
                      img {
                        opacity: 1;
                      }
                    }
                  }
                  .text {
                    text-align: left;
                    max-width: 232px;
                    min-height: 16px;
                    font-size: 15px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: 0.6px;
                    color: #4d4d4d;
                    margin-top: auto;
                    margin-bottom: auto;
                  }
                }
              }
            }
            .title {
              text-align: left;
              max-width: 445px;
              color: #000000;
            }
          }
        }
        .map {
          top: 40px;
          left: 100%;
          transform: translate3d(calc(~'-200% - 10px'), 0px, 0px);
          position: absolute;
          display: grid;
          grid-template-columns: auto 69px auto;
          .img-cont-top {
            position: relative;
            height: 69px;
            img {
              cursor: pointer;
              &.active {
                opacity: 0;
              }
              transition: .3s;
              &.one {
                right: -4px;
                width: 74.1px;
              }
              &.two {
                left: 0px;
                width: 100%;
              }
              &.three {
                width: 84px;
                left: -3px;
              }
              position: absolute;
              bottom: 0px;
            }
          }
          .img-cont-mid {
            position: relative;
            height: 35px;
            img {
              cursor: pointer;
              &.active {
                opacity: 0;
              }
              transition: .3s;
              &.four {
                right: -3px;
                width: 72px;
              }
              &.five {
                left: 0px;
                width: 100%;
              }
              &.six {
                width: 87px;
                left: -3px;
              }
              position: absolute;
              bottom: 0px;
            }
          }
          .img-cont-bot {
            position: relative;
            height: 54px;
            img {
              cursor: pointer;
              &.active {
                opacity: 0;
              }
              transition: .3s;
              &.seven {
                right: -4px;
                width: 43px;
              }
              &.eight {
                left: -3px;
                width: 73px;
              }
              &.nine {
                width: 65px;
                left: -2px;
              }
              position: absolute;
              top: -3px;
            }
          }
        }
      }
      .left {
        text-align: left;
        @media screen and (max-width: 1220px) {
          grid-area: top;
          margin-bottom: 20px;
        }
        .title {
          max-width: 446px;
        }
        .para {
          max-width: 465px;
        }
        .dashes {
          margin-bottom: 47px;
        }
      }
    }
    .iceland {
      position: relative;
      height: 389px;
      width: 573px;
      margin-left: auto;
      margin-right: auto;
      transform: scale(0.6);
      // translate: 0px -15%;
      img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 573px;
        transition: .3s;
        opacity: 1;
        &.active {
          opacity: 1;
          transform: scale(1.01);
        }
        &.inactive {
          opacity: 0;
        }
      }
      @media screen and (max-width: 1220px) {
        margin-top: 86px;
        transform: scale(1);
      }
      @media screen and (max-width: 768px) {
        transform-origin: 0 0;
        margin-left: 0px;
        margin-right: 0px;
        left: 50%;
        transform: scale(.8) translate3d(-50%, 0px, 0px);
      }
      @media screen and (max-width: 600px) {
        margin-bottom: -118px;
        transform: scale(.6) translate3d(-50% , 0px, 0px);
      }
      @media screen and (max-width: 572px) {
        transform: scale(.6) translate3d(-50% , 0px, 0px);
      }
      @media screen and (max-width: 400px) {
        margin-bottom: -176px;
        transform: scale(.45) translate3d(-50% , 0px, 0px);
      }
    }
  }
  .header {
    height: 628px;
    max-height: 628px;
    background-image: url('~@/assets/jpg/toursandactiveheader.jpg');
    background-size: auto;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    .header-image {
      display: none;
    }
    @media screen and (max-width: 1220px) {
      background-image: none;
      height: initial;
      max-height: initial;
      .header-image {
        margin-top: 48px;
        display: block;
        width: 100%;
        max-height: 440px;
        object-fit: cover;
        object-position: bottom;
      }
    }
    .content {
      position: relative;
      top: 0px;
      height: 100%;
      left: 0px;
      text-align: left;
      .title {
        padding-top: 68px;
        @media screen and (max-width: 768px) {
          padding-top: 40px;
        }
        position: relative;
        max-width: 374px;
        font-size: 48px;
        font-weight: bold;
        letter-spacing: 1.92px;
        line-height: 72px;
      }
      .para {
        padding-top: 38px;
        max-width: 374px;
        font-size: 18px;
        letter-spacing: 1.75px;
        line-height: 34px;
      }
    }
  }
  .dashes {
    margin-top: 38px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 60px;
    .dash {
      width: 8px;
      height: 2px;
      background-color: #F9DB49;
    }
  }
}
@keyframes flip {
  0% {
    transform: rotate3d(1, 0, 0, 90deg);
    background-color: #F9DB49;
  }
  25% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
  49% {
    background-color: #F9DB49;
  }
  50% {
    background-color: #00486c;
    transform: rotate3d(1, 0, 0, 90deg);
  }
  75% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
  99% {
    background-color: #00486c;
  }
  100% {
    background-color: #F9DB49;
    transform: rotate3d(1, 0, 0, 90deg);
  }
}
</style>
