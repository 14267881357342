<template lang='pug'>
	.car-information
		.header
			.content.constrainer
				img.header-image(src='@/assets/jpg/carinformation.jpg')
				h1.title Car information.
				.dashes
					.dash(v-for='n in 5')
				.para.fs Unsure what your dashboard lights mean or what tires you should use? Let us help.
		.body-section.gutter.constrainer
			//- .section-pad
			//- 	BookBanner(
			//- 		:bannerNumber="2"
			//- 	)
			//- .space-info-one.bottom.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 13' :id='665')
			//-.space-info-one.bottom.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 1300' :id='678')
			.bluecarrental-banner
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
					text="We recommend our visitors to book their car with Blue Rental Car. Find more about their offers here."
				)
			.dashboard-information
				h1.title Dashboard information.
				.dashes
					.dash(v-for='n in 5')
				.para.fs The dashboard has a dizzying array of lights and warnings we rarely see except when something is wrong. It‘s not a good feeling to see an unfamiliar light when you‘re basking in the still nature of Iceland.
			.dashboard-types
				.selection
					.item(v-for='item, index in dashboards' :class='{ checked: item.check }')
						.text.clickable(@click='selectDashboard(index)') {{ item.text }}
				.para.fs {{ selectedDashboard.para }}
				.tems(:class='{ inactive: showPopup }')
					.tem(v-for='board in selectedDashboard.content')
						img(:src='board.url' @click='viewDashboard(board)')
						.text(@click='viewDashboard(board)') {{ board.title }}
				.popup(:class='{ active: showPopup }')
					.overlay
					.inner(v-click-outside='closePopup')
						img.close.clickable(src='@/assets/close.svg' @click='closePopup')
						img.image(:src='popupData.url')
						.title {{ popupData.title }}
						.text {{ popupData.text }}
			.tires-in-iceland
				h1.title Tires in Iceland.
				.dashes
					.dash(v-for='n in 5')
				.para.fs Winter and summer tires are allowed all year round, however studded tires are only allowed during winter. Studded tires increase control and traction in dangerous snowy conditions. The following rules apply:
				.tires
					.tire
						img(src='@/assets/dash/allowed.svg')
						.heading 1 November – 14 April
						.mini-para Studded tires allowed (but not compulsory).
					.tire
						img(src='@/assets/dash/notallowed.svg')
						.heading 15 April – 31 October
						.mini-para Studded tires prohibited (except if necessary because of driving conditions).
			//- .space-info-two.bottom.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 14' :id='666')
			//-.space-info-two.bottom.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 1400' :id='679')
			.section-pad
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
					text="Safe.is recommends Blue Car Rental for booking and renting safe, reliable cars with the best service possible. Driving around our beautiful island relies heavily on your rental car."
					text-align="left"
				)
			FAQSection
</template>

<script>
import ClickOutside from 'vue-click-outside';
export default {
	name: 'CarInformation',
	metaInfo: {
		title: 'Safe.is - Car information.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:url',
				property: 'og:url',
				content: 'https://safe.is/car-information/index.html'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/carinformation.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'Safe.is - Car information.'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'Be sure to know about special car requirements and general information about your car before driving in Iceland.'
			},
			{
				name: 'description',
				property: 'description',
				content: 'Be sure to know about special car requirements and general information about your car before driving in Iceland.'
			}
		]
	},
	directives: {
		ClickOutside
	},
	data: function () {
		return {
			showPopup: false,
			popupData: {
				title: 'Check engine',
				text: 'Usually an indicator that the engine must be diagnosed by a professional.',
				url: '/dash/engine.svg'
			},
			dashboards: [
				{
					check: true,
					text: 'Safety signals',
					para: 'Safety signals are an indicator of the health of your vehicle and must not be ignored. These are some of the most common safety symbols.',
					content: [
						{
							url: '/dash/engine.svg',
							title: 'Check engine',
							text: 'Usually an indicator that the engine must be diagnosed by a professional.'
						},
						{
							url: '/dash/coolant.svg',
							title: 'Coolant temperature',
							text: 'The coolant temperature has exceeded normal limits.'
						},
						{
							url: '/dash/oil.svg',
							title: 'Oil pressure',
							text: 'Indicates a loss of oil pressure. The car should be stopped and oil added to the engine.'
						},
						{
							url: '/dash/battery.svg',
							title: 'Battery charge alert',
							text: 'Voltage level is below normal level.'
						},
						{
							url: '/dash/break.svg',
							title: 'Brake alert',
							text: 'Indicates either that the parking brake is on or a problem with the braking system.'
						},
						{
							url: '/dash/service.svg',
							title: 'Service vehicle soon',
							text: 'The car requires service soon.'
						}
					]
				},
				{
					check: false,
					text: 'Information signals',
					para: 'Information signals are important indicators of the functioning of your vehicle. They are rarely a cause for great alarm but it is good to know what they mean.',
					content: [
						{
							url: '/dash/engine2.svg',
							title: 'Check engine',
							text: 'On some cars, a yellow check engine light means investigate the problem and a red one means stop right now.'
						},
						{
							url: '/dash/coolant2.svg',
							title: 'Coolant temperature',
							text: 'Yellow (sometimes orange) dashboard lights usually mean something needs to be repaired or serviced soon and to operate with caution.'
						},
						{
							url: '/dash/traction.svg',
							title: 'Traction control',
							text: 'Illuminates when road conditions are slippery, and the car is skidding.'
						},
						{
							url: '/dash/washer.svg',
							title: 'Washer fluid',
							text: 'Washer fluid is low.'
						},
						{
							url: '/dash/fog.svg',
							title: 'Fog lamp',
							text: 'The fog lamps are on.'
						},
						{
							url: '/dash/glow.svg',
							title: 'Glow plug',
							text: 'On diesel cars only, the glow plug is warming up; do not start the car until the light shuts off.'
						},
						{
							url: '/dash/lamp.svg',
							title: 'Lamp out',
							text: 'An exterior light is not working.'
						},
						{
							url: '/dash/cruise.svg',
							title: 'Cruise control',
							text: 'Cruise control has been activated.'
						},
						{
							url: '/dash/tire.svg',
							title: 'Tire pressure',
							text: 'Indicates that the tire pressure is not correct. Stop at the next gas station to find tire air compressor.'
						}
					]
				}
			]
		};
	},
	computed: {
		selectedDashboard: function () {
			let dashboard = {};
			for (let i = 0; i < this.dashboards.length; i++) {
				if (this.dashboards[i].check) {
					dashboard = this.dashboards[i];
				}
			}
			return dashboard;
		}
	},
	mounted: function () {
		this.$store.state.keydex += 1;
	},
	methods: {
		closePopup: function () {
			this.showPopup = false;
		},
		viewDashboard: function (item) {
			this.popupData = {
				title: item.title,
				text: item.text,
				url: item.url
			};
			setTimeout(() => {
				this.showPopup = true;
			}, 100);
		},
		selectDashboard: function (index) {
			for (let i = 0; i < this.dashboards.length; i++) {
				if (index === i) {
					this.dashboards[i].check = true;
				} else {
					this.dashboards[i].check = false;
				}
			}
		}
	}
};
</script>

<style lang='less'>
	.car-information {
		.space-info-one {
			min-height: 1px;
			&.main-mobile {
				display: none;
				margin-top: 48px;
				margin-bottom: 48px;
			}
			&.main-desktop {
				margin-top: 96px;
				margin-bottom: 96px;
			}
			@media screen and (max-width: 768px) {
				&.main-mobile {
					display: block;
				}
				&.main-desktop {
					// display: none;
				}
			}
		}
		.space-info-two {
			min-height: 1px;
			&.main-mobile {
				display: none;
				margin-top: 48px;
				margin-bottom: 48px;
			}
			&.main-desktop {
				margin-top: 96px;
				margin-bottom: 96px;
			}
			@media screen and (max-width: 768px) {
				&.main-mobile {
					display: block;
				}
				&.main-desktop {
					// display: none;
				}
			}
		}
		.body-section {
			.bluecarrental-banner {
				margin-top: 98px;
				margin-bottom: 48px;
				@media screen and (max-width: 768px) {
					margin-top: 48px;
				}
			}
			.tires-in-iceland {
				margin-top: 98px;
				margin-bottom: 48px;
				@media screen and (max-width: 768px) {
					margin-top: 48px;
				}
				.tires {
					display: grid;
					grid-template-columns: 1fr 1fr;
					@media screen and (max-width: 968px) {
						grid-template-columns: 1fr;
					}
					.tire {
						img {
							width: 100%;
							max-height: 158px;
							margin-bottom: 58px;
							margin-top: 80px;
							@media screen and (max-width: 968px) {
								margin-bottom: 48px;
							}
						}
						.heading {
							margin-left: auto;
							margin-right: auto;
							max-width: 390px;
							font-size: 30px;
							font-weight: normal;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.5;
							letter-spacing: 2.5px;
							text-align: center;
							color: #000000;
							@media screen and (max-width: 968px) {
								font-size: 24px;
							}
						}
						.mini-para {
							margin-left: auto;
							margin-right: auto;
							max-width: 374px;
							font-size: 18px;
							font-weight: normal;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.62;
							letter-spacing: 1.8px;
							text-align: center;
							color: #000000;
							@media screen and (max-width: 968px) {
								margin-top: 24px;
								font-size: 16px;
								line-height: 1.63;
								letter-spacing: 1.3px;
							}
						}
					}
				}
				.title {
					text-align: left;
					max-width: 742px;
				}
				.dashes {
					margin-bottom: 47px;
				}
				.para {
					text-align: left;
					max-width: 1024px;
				}
			}
			.dashboard-types {
				padding-top: 112px;
				@media screen and (max-width: 768px) {
					padding-top: 48px;
				}
				.popup {
					pointer-events: none;
					.overlay {
						position: absolute;
						top: 0px;
						left: 0px;
						right: 0px;
						bottom: 0px;
						background-color: rgba(0,0,0,0.15);
					}
					.inner {
						padding-top: 96px;
						padding-bottom: 96px;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%, -50%, 0px) scale(.5);
						position: fixed;
						max-height: 576px;
						max-width: 536px;
						width: 100%;
						background-color: #FFFFFF;
						box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
						transition: .3s;
						@media screen and (max-width: 968px) {
							padding-top: 24px;
							padding-bottom: 24px;
						}
						.close {
							position: absolute;
							top: 16px;
							right: 16px;
						}
						.image {
							margin-bottom: 64px;
							width: 220px;
							@media screen and (max-width: 968px) {
								margin-bottom: 24px;
								width: 110px;
							}
							margin-left: auto;
							margin-right: auto;
						}
						.title {
							margin-bottom: 24px;
							color: #000000;
							font-weight: bold;
							font-family: Montserrat;
							font-size: 21px;
							letter-spacing: 2.49px;
							line-height: 32px;
							text-align: center;
							margin-left: auto;
							margin-right: auto;
							@media screen and (max-width: 968px) {
								margin-bottom: 12px;
								font-size: 18px;
							}
						}
						.text {
							margin-left: auto;
							margin-right: auto;
							max-height: 137px;
							max-width: 404px;
							color: #000000;
							font-family: Montserrat;
							font-size: 21px;
							letter-spacing: 2.49px;
							line-height: 32px;
							text-align: center;
							@media screen and (max-width: 968px) {
								font-size: 16px;
							}
						}
					}
					transition: .3s;
					opacity: 0;
					&.active {
						pointer-events: initial;
						opacity: 1;
						.inner {
							transform: translate3d(-50%, -50%, 0px) scale(.95);
						}
					}
				}
				.tems {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-row-gap: 55px;
					@media screen and (max-width: 782px) {
						grid-template-columns: 1fr 1fr;
					}
					&.inactive {
						pointer-events: none;
					}
					.tem {
						img {
							cursor: pointer;
							width: auto;
							max-height: 97px;
							object-fit: contain;
						}
						.text {
							cursor: pointer;
							margin-top: 46px;
							margin-left: auto;
							margin-right: auto;
							max-width: 244px;
							height: 90px;
							font-family: Montserrat;
							font-size: 30px;
							font-weight: normal;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.5;
							letter-spacing: 2.5px;
							text-align: center;
							color: #000000;
							@media screen and (max-width: 1220px) {
								font-size: 18px;
							}
							@media screen and (max-width: 375px) {
								font-size: 16px;
							}
						}
					}
				}
				.para {
					margin-left: auto;
					margin-right: auto;
					max-width: 843px;
					margin-bottom: 86px;
				}
				.selection {
					display: grid;
					grid-template-columns: auto auto;
					max-width: 748px;
					margin-left: auto;
					margin-right: auto;
					.item {
						min-height: 87px;
						@media screen and (max-width: 768px) {
							min-height: 64px;
						}
						.text {
							display: inline-block;
							margin-left: auto;
							margin-right: auto;
							padding-bottom: 10px;
							font-size: 24px;
							font-weight: bold;
							font-style: normal;
							font-stretch: normal;
							line-height: normal;
							letter-spacing: 1px;
							text-align: center;
							color: #cccccc;
							border-bottom: 3px solid rgba(0, 0, 0, 0);
							transition: .3s;
							transform: translate3d(0, 0, 0, 0);
							@media screen and (max-width: 1220px) {
								font-size: 18px;
							}
							@media screen and (max-width: 500px) {
								font-size: 12px;
							}
						}
						&.checked {
							.text {
								padding-bottom: 4px;
								color: black;
								border-bottom: 3px solid #f9db49;
							}
						}
					}
				}
			}
			.dashboard-information {
				.title {
					text-align: left;
					max-width: 742px;
					height: 65px;
				}
				.dashes {
					@media screen and (max-width: 768px) {
						margin-top: 64px;
					}
					margin-bottom: 47px;
				}
				.para {
					text-align: left;
					max-width: 1024px;
					@media screen and (max-width: 1148px) {
						max-width: 600px;
					}
				}
			}
		}
		.header {
			height: 628px;
			max-height: 628px;
			background-image: url('~@/assets/jpg/carinfoheader.jpg');
			background-size: cover;
			background-position: 50% 100%;
			.header-image {
				display: none;
			}
			@media screen and (max-width: 1220px) {
				background-image: none;
				height: initial;
				max-height: initial;
				.header-image {
					margin-top: 48px;
					display: block;
					width: 100%;
					max-height: 440px;
					object-fit: cover;
					object-position: bottom;
				}
			}
			.content {
				position: relative;
				top: 0px;
				height: 100%;
				left: 0px;
				text-align: left;
				.title {
					padding-top: 68px;
					@media screen and (max-width: 768px) {
						padding-top: 40px;
					}
					position: relative;
					max-width: 374px;
				}
				.para {
					padding-top: 38px;
					max-width: 374px;
				}
				.button {
					display: grid;
					grid-template-columns: 80% 20%;
					height: 48px;
					width: 260px;
					background-color: #FFFFFF;
					box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
					border-left: 4px solid #AF7595;
					margin-top: 58px;
					.text {
						color: black;
						height: 30px;
						width: 161px;
						font-size: 18px;
						font-weight: 600;
						letter-spacing: 1.5px;
						line-height: 48px;
						padding-left: 32px;
					}
					.arrow {
						position: relative;
						top: 50%;
						transform: translate3d(0px, -50%, 0px);
					}
				}
			}
		}
		.dashes {
			margin-top: 38px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			width: 60px;
			.dash {
				width: 8px;
				height: 2px;
				background-color: #F9DB49;
			}
		}
	}
</style>
